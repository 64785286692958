app-reviews-buckets {
    display: block;
    
    .__reviews-buckets__form {
        display: grid;
        grid-template-columns: min-content 1fr;
        grid-auto-rows: 1.75rem;
        align-items: center;

        app-check-input {
            margin-top: calc(-1 * var(--spacer-xs));

            app-label-container .__label {
                margin-bottom: 0 !important;
            }
        }

        app-chart-percentage {
            width: auto;
        }

        ui-star-score {
            height: var(--spacer-md);
        }
    }
}
