smvd-ui-reviews-segments {
    display: block;

    .__reviews-segments__form {
        app-check-input {
            height: 100%;

            app-label-container .__label {
                margin-bottom: 0 !important;
            }
        }
    }
}

smvd-ui-reviews-segments {
    .__housematch-score {
        width: 1.5rem;
    }
}
