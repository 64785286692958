<app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
    <div *ngIf="custom" class="__label-container u-w100p" [class.__grid-container]="gridContainer">
        <label
            *ngFor="let option of innerOptions; let last = last"
            class="__input-container u-flex-1-1-0"
            [ngClass]="{ '__input-container--last': last, '__input-container--ignore-min-width': ignoreMinWidth }"
        >
            <input
                class="u-label-select-input"
                type="radio"
                [(ngModel)]="innerModel"
                (ngModelChange)="onModelChange()"
                [value]="option?.value"
                [name]="option?.name"
                [disabled]="option?.disabled || innerDisabled"
            />
            <div
                class="u-label-select-container u-flex-align-items-center u-flex-column"
                [ngClass]="{ 'u-padding': hasPadding, 'u-padding-half': hasPaddingHalf, 'u-h100p': optionsEqualHeight }"
                [attr.data-testid]="option?.id"
            >
                <app-icon
                    *ngIf="option?.icon"
                    [icon]="option.icon"
                    [size]="uiIconSize.Inherit"
                    class="u-mw100p __icon u-margin-bottom-half"
                ></app-icon>
                <img *ngIf="option?.imageUrl" class="u-mw100p __image u-margin-bottom-half" [src]="option.imageUrl" />
                <app-svg *ngIf="option?.svg" [name]="option?.svg" class="__svg u-margin-bottom-half"></app-svg>
                <span *ngIf="option?.label" class="__label u-text-align-center">{{ option.label }}</span>
                <span *ngIf="option?.labelResource" class="__label u-text-align-center">{{ option.labelResource | translate }}</span>
                <ng-container *ngIf="templateRef">
                    <ng-container [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: templateData }"></ng-container>
                </ng-container>
                <span *ngIf="option?.extraInfo" class="__label u-font-size-small u-text-align-center">{{ option?.extraInfo }}</span>
            </div>
        </label>
    </div>
    <div *ngIf="!custom">
        <mat-radio-group
            [(ngModel)]="innerModel"
            [disabled]="innerDisabled"
            [class.u-flex-row]="direction === 'horizontal'"
            [class.u-flex-column]="!direction || direction === 'vertical'"
            [class.__grouped]="variant === variants.Grouped"
            (ngModelChange)="onModelChange()"
        >
            <mat-radio-button
                *ngFor="let option of innerOptions; let last = last"
                [id]="option?.id"
                [disableRipple]="true"
                [class]="(option.customClass ? option.customClass + ' ' : '') + (option?.meter ? 'is-' + option.meter : '')"
                [class.u-margin-right-double]="!last && direction === 'horizontal'"
                [class.u-margin-bottom]="variant !== variants.Grouped && !last && (direction === 'vertical' || !direction)"
                [value]="option?.value"
                [name]="option?.name"
                [disabled]="option?.disabled"
                [matBadge]="option.tag"
                [matBadgeHidden]="!option.tag"
                [matBadgeWide]="true"
            >
                <div *ngIf="option?.label || option?.labelResource" class="__label u-flex-column u-flex-align-items-start">
                    <span>{{ option?.label || (option?.labelResource | translate) }}</span>
                    <span
                        *ngIf="option?.description || option?.descriptionResource"
                        class="u-color-muted u-font-size-small"
                        [innerHTML]="option?.description || (option.descriptionResource | translate)"
                    ></span>
                </div>
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <mat-error class="u-margin-top-half" *ngIf="isErrorState()">{{ getFirstErrorState() | errorState }}</mat-error>
</app-label-container>
