@import '../../../scss/base/mixins';
@import '../../../scss/base/variables';

:host {
    display: inline-block;
}

app-svg-checked {
    .__svg {
        svg {
            height: $svg-height;
            width: auto;
            color: $color-gray;
        }
    }

    .__check,
    .__error {
        position: absolute;
        right: -2px;
        bottom: 0;

        svg {
            height: 18px;
            width: auto;
        }
    }

    .__check {
        color: $color-success;
    }

    .__error {
        color: $color-error;
    }
}
