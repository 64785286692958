smvd-ui-contact-info {
    .ui-button {
        padding: 0 !important;
        vertical-align: middle !important;
        white-space: nowrap !important;
    }

    display: block;
    background-color: var(--color-white);
    padding: var(--spacer-default);

    .__info-direction--horizontal {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: var(--spacer-sm);

        section {
            &:not(:last-of-type) {
                margin-right: 1rem;
            }
        }
    }

    .__info-direction--vertical {
        section + section {
            margin-top: var(--spacer-sm);
        }
    }

    app-svg-illustration {
        width: 1rem;
    }

    .x-logo path {
        fill: var(--color-muted);
    }
}
