app-svg-illustration,
a[app-svg-illustration],
smvd-ui-svg-illustration,
a[smvd-ui-svg-illustration] {
    color: var(--color-accent);
    display: block;
    height: 100%;
    min-height: auto;

    div {
        display: flex;
        height: 100%;
        justify-content: center;

        svg {
            display: flex;
            height: 100%;
            width: 100%;
        }
    }
}
