@import '../../../scss/base/mixins';

app-todo-template {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: relative;

    .__todo-template-header {
        margin-bottom: var(--spacer-xs);

        & + [app-button] {
            margin-top: var(--spacer-sm);
        }
    }

    .__todo-template-cta {
        @include phone-landscape-up {
            height: 0;
        }

        [app-button] {
            display: inline-flex;
            height: auto;
        }

        &:empty {
            display: none;
        }

        & > :not(.u-margin-none) .ui-button-md {
            margin-top: calc(-1 * var(--spacer-sm));
        }
    }

    // align with variant header
    span ~ .__todo-template-cta [app-button]:not(.mat-link-button) {
        @include phone-landscape-up {
            transform: translateY(-0.25rem);
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        & ~ .__todo-template-cta {
            display: flex;
            align-items: center;
            height: auto;
        }
    }
}
