import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@core/interfaces';
import { AddressUtils } from '@core/utils';

@Pipe({
    name: 'formatStreet',
    standalone: true,
})
export class FormatStreetPipe implements PipeTransform {
    transform(value: Address) {
        if (!value) return '';
        return AddressUtils.buildFormattedStreet(value);
    }
}
