import { childMatcher } from "../matcher.js";
var filter = Array.prototype.filter;
function children() {
  return this.children;
}
function childrenFilter(match) {
  return function () {
    return filter.call(this.children, match);
  };
}
export default function (match) {
  return this.selectAll(match == null ? children : childrenFilter(typeof match === "function" ? match : childMatcher(match)));
}