@import '../../../scss/base/mixins';
//@import '@material-design-icons/font/outlined';
//@import '@material-design-icons/font/round';
//@import '@material-design-icons/font/filled';

app-nps-review-item {
    .__nps-template {
        position: relative;
        display: grid;
        grid-template-columns: 2rem auto;
        grid-column-gap: var(--spacer-default);
        grid-template-areas:
            'score quote'
            'score note'
            'score actions';

        &:not(:first-of-type) {
            padding-top: var(--spacer-md);
        }
    }

    .__nps-score {
        display: inline-flex;
        align-self: flex-start;

        .__logo-google {
            margin-top: 0.2rem;
        }

        .__logo-facebook {
            color: $color-facebook;
        }
    }

    section {
        grid-area: quote;
        position: relative;
        margin: 0;

        @include up-to-and-including-phone-landscape() {
            padding-right: 0rem;
        }
    }

    .__nps-note {
        grid-area: note;

        app-note {
            display: table !important;
            width: 100%;

            &.__note-internal {
                --note-background-color: var(--color-gray-lightest);
            }
        }
    }

    .__nps-template:not(:last-of-type) figure {
        padding-bottom: var(--spacer-md);
    }

    .__nps-content {
        min-height: var(--spacer-default);
        margin: 0;
        word-break: break-word;
        padding-right: var(--spacer-double); // Spacing for sharing button
    }

    .__nps-actions {
        grid-area: actions;

        &:empty {
            display: none;
        }
    }

    .__nps-share-button {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .mat-mdc-chip.mat-accent {
        background: var(--color-error);
    }

    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
    .mat-chip-list {
        margin-left: 12px;
    }
}
