import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { ReviewAdminInfo } from '@ui/reviews/interfaces/review-admin-info';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'ui-review-admin-info',
    standalone: true,
    imports: [NgOptimizedImage, TranslateModule],
    template: `
        <div class="u-w100p u-padding u-border-radius u-background-tertiary-default">
            <p class="u-margin-bottom-half u-text-uppercase u-font-weight-medium u-font-size-sm u-color-content-secondary-default">
                {{ 'REVIEWS.ADMIN_INFO.TITLE' | translate }}
            </p>
            <div class="u-flex-row u-flex-align-items-center">
                <img ngSrc="https://assets.smooved.be/icons/email.svg" width="18" height="18" class="u-margin-right-half" />
                <span>{{ adminInfo.email }}</span>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewAdminInfoComponent {
    @Input() adminInfo: ReviewAdminInfo;
}
