@import '../../scss/base/variables';

app-todo-list {
    display: block;
    padding: var(--spacer-default);
    border: 1px solid var(--color-gray-light);
    border-radius: var(--border-radius-lg);
    max-width: 100%;
    overflow: hidden;
    margin-bottom: var(--spacer-default);
    background-color: var(--color-white);

    &.__todo-list--completed {
        background-color: var(--color-gray-lightest);
        border: 1px solid var(--color-gray-lightest);
    }

    .__todo-list__header {
        border-bottom: 1px solid $divider-color;
        padding-bottom: var(--spacer-sm-md);
    }

    .__todo-list__header ~ .__todo-list__body {
        margin-top: var(--spacer-sm-md);
    }

    .__todo-list__footer {
        margin-top: var(--spacer-default);
        padding-top: var(--spacer-default);
        border: 2px solid transparent;
        position: relative;
        z-index: 0;

        &:empty {
            display: none;
        }

        &:after {
            content: '';
            position: absolute;
            background-color: var(--color-gray-medium);
            left: calc(-1 * var(--spacer-default));
            right: calc(-1 * var(--spacer-default));
            bottom: calc(-1 * var(--spacer-default));
            top: 0;
            z-index: -1;
            border-radius: var(--border-radius-lg);
        }
    }

    &.__todo-list--completed .__todo-list__footer::after {
        background-color: var(--color-white);
    }

    [todo] ~ [todo] {
        display: block;
        margin-top: var(--spacer-default);
    }
}
