<mat-button-toggle-group
    class="__button-group"
    #group="matButtonToggleGroup"
    [disabled]="disabled"
    [hideSingleSelectionIndicator]="true"
    [hideMultipleSelectionIndicator]="true"
>
    <mat-button-toggle
        *ngFor="let option of options; trackBy: trackBy"
        class="u-font-size-default"
        [checked]="isChecked(option)"
        (click)="activate(option)"
    >
        {{ option.label }}
    </mat-button-toggle>
</mat-button-toggle-group>
