@import '../../scss/base/variables';
@import '../../scss/base/mixins';

smvd-ui-dynamic-address-input {
    display: block;

    .__form {
        display: grid;
        grid-template-columns: repeat(24, 1fr);
        grid-template-rows: repeat(1, 1fr);

        @include up-to-and-including-phone-landscape {
            grid-gap: 0.5rem;
        }

        @include tablet-portrait-up {
            grid-gap: 1rem;
        }

        &:not(.__form--small) {
            .__street {
                @include tablet-portrait-up {
                    grid-column: 1 / span 15;
                }
            }

            .__house-number {
                @include tablet-portrait-up {
                    grid-column: 16 / span 6;
                }
            }

            .__bus-number {
                min-width: 4rem;
                @include tablet-portrait-up {
                    grid-column: 22 / span 3;
                }
            }

            .__zip-code {
                @include phone-landscape-up {
                    grid-column: 1 / span 8;
                }
            }

            .__city {
                @include phone-landscape-up {
                    grid-column: 9 / span 16;
                }
            }
        }
    }

    .__street {
        grid-column: 1 / span 24;
    }

    .__house-number {
        grid-column: 1 / span 8;
    }

    .__bus-number {
        grid-column: 9 / span 8;
    }

    .__zip-code {
        grid-column: 1 / span 8;
    }

    .__city {
        grid-column: 9 / span 18;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix.mat-form-field-infix {
        width: auto;
    }
}
