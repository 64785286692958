import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import merge from 'deepmerge';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
class MultiTranslateHttpLoader {
  /**
   * @param {?} http
   * @param {?} resources
   */
  constructor(http, resources) {
    this.http = http;
    this.resources = resources;
  }
  /**
   * @param {?} lang
   * @return {?}
   */
  getTranslation(lang) {
    const /** @type {?} */requests = this.resources.map(resource => {
      const /** @type {?} */path = resource.prefix + lang + resource.suffix;
      return this.http.get(path).pipe(catchError(res => {
        console.error("Could not find translation file:", path);
        return of({});
      }));
    });
    return forkJoin(requests).pipe(map(response => merge.all(response)));
  }
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */

export { MultiTranslateHttpLoader };
