@import '../../scss/base/variables';
@import '../../scss/base/mixins';

app-select-input {
    display: inline-block;

    .__label-container {
        display: flex;
        @include up-to-and-including-phone-landscape {
            flex-direction: column;
        }
        @include tablet-portrait-up {
            flex-direction: row;
            align-items: stretch;
        }

        &.__grid-container {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            align-items: stretch;
            grid-auto-rows: 1fr;
            @include tablet-portrait-up {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 1rem;
            }
            @include desktop-up {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }

    .__input-container {
        &:not(.__input-container--last) {
            @include up-to-and-including-phone-landscape {
                margin-bottom: 1rem;
            }
            @include tablet-portrait-up {
                margin-right: 1rem;
            }
        }

        &:not(.__input-container--default) {
            display: block;
            width: 100%;

            @include tablet-portrait-up {
                max-width: 228px;
            }
        }

        &--default {
            input,
            label {
                cursor: pointer;
            }
        }
    }

    .__image {
        height: 40px;
        @include tablet-landscape-up {
            height: 60px;
        }
    }

    .__label {
        white-space: normal;
    }

    .__svg {
        svg {
            height: 40px;
            width: auto;
            @include tablet-landscape-up {
                height: 60px;
            }
        }

        color: var(--color-gray);
    }

    .__icon {
        height: 40px;
        color: $color-gray;
        font-size: 40px;

        @include tablet-landscape-up {
            height: 60px;
            font-size: 60px;
        }
    }

    .mat-mdc-radio-button .__label {
        white-space: normal;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-checked .__label {
        color: var(--color-brand-5);
    }

    .mat-mdc-radio-group.__grouped {
        border: 1px solid var(--color-gray-light);
        border-radius: var(--border-radius-lg);
        max-width: 100%;

        .mat-mdc-radio-button {
            padding: var(--spacer-default);

            &:not(:first-child) {
                border-top: 1px solid var(--color-gray-light);
            }

            & > label {
                align-items: flex-start;
                font-weight: 500;
            }

            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
            .mat-radio-container {
                margin-top: 5px;
            }

            .__label {
                color: inherit;
                padding-left: var(--spacer-sm);
            }

            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
            &.mat-radio-checked {
                background-color: var(--color-gray-lightest);
            }
        }

        .mat-badge-content {
            position: absolute;
            right: var(--spacer-sm) !important;
            transform: unset !important;

            @include tablet-portrait-up {
                top: var(--spacer-sm);
            }
        }
    }
}
