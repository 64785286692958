@import '../../scss/base/variables';

ui-language-menu {
    .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-wrapper {
        padding-bottom: 0;
    }

    .mat-mdc-form-field-underline {
        display: none;
    }

    .mat-mdc-form-field-infix {
        width: auto;
    }

    .mat-mdc-select {
        width: $language-switcher-width;
    }

    .mat-mdc-select-value {
        color: inherit;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled),
    .mat-mdc-form-field-focus-overlay {
        background: none;
        padding: 0;
    }
}
